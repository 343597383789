.Toastify__toast--success,
.Toastify__toast--error {
  background: white;
  color: #202538;
}
.Toastify__toast.Toastify__toast--error {
  border-left-color: #ff0000;
}

.Toastify__toast.Toastify__toast--success {
  border-left-color: #52c41a;
}

.Toastify__toast-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  top: 58px !important;
}

.Toastify__toast-body {
  margin: auto 0 !important;
  font-size: 12px;

  p {
    font-size: 12px;
  }
}
.Toastify__toast {
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.10);
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #fff;
  min-height: 52px;
  border-left: 4px solid;
}

.Toastify__toast > img {
  cursor: pointer;
  transition: 250ms ease-in-out;
}

.Toastify__toast > img:hover {
  transform: rotate(-180deg);
}

/* .Toastify__toast--error {
  color: #dcb40b;
} */

.Toastify__progress-bar {
  height: 3px;
}

/* .Toastify__toast--error > .Toastify__progress-bar {
  background: #bd5230;
} */
